<template>
  <div oncontextmenu="return false;" onmousedown="return false" onselectstart="return false">
    <section class="text-gray-700 body-font">
      <div
          class="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row"
      >
        <div
            class="flex flex-col items-center w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 xl:mr-20 md:pr-24 md:items-start md:text-left md:mb-0 lg:text-center"
        >
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            Privacy policy
          </h1>


          <h5 class="text-white"><b>General Information</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >The following notices provide a simple overview of what happens to your personal data when you visit this website. Personal data is any data by which you can be personally identified. For more detailed information on data protection, please refer to our privacy policy listed below this text.</p>

          <h5 class="text-white"><b>Data collection on this website</b></h5>

          <h5 class="text-white"><b>Who is responsible for data collection on this website?</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >Data processing on this website is carried out by the website operator. You can find his contact details in the imprint of this website.</p>

          <h5 class="text-white"><b>How do we collect your data?</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >On the one hand, your data is collected by you providing it to us. This can be, for example, data that you enter in a contact form.
            Other data is collected automatically or after your consent when you visit the website by our IT systems. This is mainly technical data (e.g. IP address, time of page view, UUID and name of your Minecraft® account). This data is collected automatically as soon as you enter this website or use another system provided by us.</p>

          <h5 class="text-white"><b>What do we use your data for?</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >Your data is collected solely to ensure error-free provision of the website. Any form of analysis of your user behavior does not take place.</p>

          <h5 class="text-white"><b>What rights do you have regarding your data?</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >You have the right at any time to receive information free of charge about the origin, recipient and purpose of your stored personal data. You also have a right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request the restriction of the processing of your personal data under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.
            For this purpose, as well as for further questions on the subject of data protection, you can contact us at any time at the address given in the imprint.</p>


          <h5 class="text-white"><b>2 General notes and mandatory information</b></h5>

          <h5 class="text-white"><b>Data protection</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.
          When you use this website, various personal data are collected. Personal data is data with which you can be personally identified. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done.
            We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail) can have security gaps. Complete protection of data against access by third parties is not possible.</p>


          <h5 class="text-white"><b>Note on the responsible office</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >The responsible party for data processing on this website is:

          Vincent Molnár<br/>Bahnhofstr. 17<br/>09618 Brand-Erbisdorf<br/>Germany<br/>
          <br/>E-Mail: globalchataddon@gmail.com<br/>
          Telephone: +49 3732 2527326<br/>

            The responsible party is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, e-mail addresses or similar).</p>

          <h5 class="text-white"><b>Storage period</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >Unless a more specific storage period has been specified within this data protection declaration, your personal data will remain with us until the purpose for the data processing no longer applies. If you assert a legitimate request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. retention periods under tax or commercial law); in the latter case, the data will be deleted once these reasons no longer apply.</p>

          <h5 class="text-white"><b>Revocation of your consent to data processing</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.</p>

          <h5 class="text-white"><b>Right to object to data collection in special cases and to direct marketing (Art. 21 DSGVO).</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >If the data processing is based on Art. 6 (1) lit. E or F DSGVO, you have the right to object to the processing of your personal data at any time for reasons arising from your particular situation; this also applies to profiling based on these provisions. The respective legal basis on which processing is based can be found in this privacy policy. If you object, we will no longer process your personal data concerned unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves the purpose of asserting, exercising or defending legal claims (objection under Article 21(1) DSGVO).
            If your personal data is processed for the purpose of direct marketing, you have the right to object at any time to the processing of personal data concerning you for the purpose of such marketing; this also applies to profiling, insofar as it is related to such direct marketing. If you object, your personal data will subsequently no longer be used for the purpose of direct advertising (objection pursuant to Art. 21 (2) DSGVO).</p>

          <h5 class="text-white"><b>Right of complaint to the competent supervisory authority</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >In the event of violations of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority, in particular in the Member State of their habitual residence, their place of work or the place of the alleged violation. The right of appeal is without prejudice to any other administrative or judicial remedy.</p>

          <h5 class="text-white"><b>Right to data portability</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >You have the right to have data that we process automatically on the basis of your consent or in performance of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another responsible party, this will only be done insofar as it is technically feasible.</p>

          <h5 class="text-white"><b>SSL or TLS encryption</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >For security reasons and to protect the transmission of confidential content, such as orders or requests that you send to us as the site operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.
            If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.</p>

          <h5 class="text-white"><b>Information, deletion and correction</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipient and the purpose of data processing and, if necessary, a right to correction or deletion of this data. For this purpose, as well as for further questions on the subject of personal data, you can contact us at any time at the address given in the imprint.</p>

          <h5 class="text-white"><b>Right to restriction of processing</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >You have the right to request the restriction of the processing of your personal data. To do this, you can contact us at any time at the address given in the imprint. The right to restriction of processing exists in the following cases:
          <br>
          If you dispute the accuracy of your personal data stored by us, we usually need time to verify this. For the duration of the review, you have the right to request the restriction of the processing of your personal data.
            <br>
          If the processing of your personal data happened/is happening unlawfully, you can request the restriction of data processing instead of deletion.
            <br>
          If we no longer need your personal data, but you need it to exercise, defend or assert legal claims, you have the right to request restriction of the processing of your personal data instead of erasure.
            <br>
          If you have lodged an objection pursuant to Art. 21 (1) DSGVO, a balancing of your and our interests must be carried out. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data.
            <br>
            If you have restricted the processing of your personal data, this data may - apart from being stored - only be processed with your consent or for the assertion, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State.</p>


        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyComponent",
};
</script>
