<template>
  <div class="privacy">
    <PrivacyComponent/>
  </div>
</template>

<script>
import PrivacyComponent from '@/components/PrivacyComponent.vue'

export default {
  name: 'Privacy',
  components: {
    PrivacyComponent
  }
}
</script>
